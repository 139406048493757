import styled from "styled-components"
import { TransitionStatus } from "react-transition-group/Transition"

interface IAnimationProps {
  status: TransitionStatus
}

export const Animation = styled.div<IAnimationProps>`
  transition: 1.5s;
  opacity: ${({ status }) =>
    status === "entering" || status === "entered" ? 1 : 0};
`
