/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { StyledSystemProps } from "../styleguide/dynamic-styled-component"

import Header from "./header"
import Style from "./layout.style"
import "./layout.css"

interface Props {
  location: Location
  children?: any
}

type LayoutProps = StyledSystemProps & Props

const Layout = (props: LayoutProps) => {
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  // var {children, ...stuff} = props;
  const { location, children, ...passedProps } = props

  return (
    <>
      <Header siteTitle={site.siteMetadata.title} />
      <Style.Layout>
        <main {...passedProps}>{children}</main>
        {/* <footer>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </footer> */}
      </Style.Layout>
    </>
  )
  // return (
  //   <Style.Container>
  //     <Header siteTitle={site.siteMetadata.title} />
  //     <Style.Section>
  //       <Style.Content>
  //         <Style.Layout>
  //           <main {...passedProps}>{children}</main>
  //         </Style.Layout>
  //       </Style.Content>
  //     </Style.Section>
  //     <footer>
  //       © {new Date().getFullYear()}, Built with
  //       {` `}
  //       <a href="https://www.gatsbyjs.org">Gatsby</a>
  //     </footer>
  //   </Style.Container>
  // )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
