import React, { forwardRef } from "react"

import { StyledBurger } from "./burger.style"

interface IBurgerProps {
  isOpen: boolean
  ref?:
    | ((instance: HTMLButtonElement | null) => void)
    | React.RefObject<HTMLButtonElement>
    | null
    | undefined
}

type BurgerProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> &
  IBurgerProps

// const Burger = forwardRef(({ ref, ...props }: BurgerProps) => {
//   return (
//     <StyledBurger {...props} ref={ref}>
//       <div />
//       <div />
//       <div />
//     </StyledBurger>
//   )
// })

const Burger = (props: BurgerProps) => {
  return (
    <StyledBurger {...props}>
      <div />
      <div />
      <div />
    </StyledBurger>
  )
}

export default Burger
