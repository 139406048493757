import styled from "styled-components"

import NavLink from "./navlink"

const Menu = styled.nav`
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  text-align: center;
  top: 0;
  left: 0;
  z-index: 200;
  background-color: white;
`

const PageLink = styled(NavLink.Page)`
  font-size: 40px;
  height: 100px;
  text-align: center;
`

const ExternalLink = styled(NavLink.External)`
  font-size: 40px;
  height: 100px;
  text-align: center;
`

export default {
  ExternalLink,
  Menu,
  PageLink,
}
