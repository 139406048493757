import React, { useState } from "react"

import Style from "./navbar.style"
import LinkedInIcon from "../icon-linked-in"
import GithubIcon from "../icon-github"
import NavLink from "./navlink"
import Menu from "./menu"
// import { MenuButton } from "./navbar-mobile.style"
import Burger from "./burger"
import { LeftBox } from "./navbar-mobile.style"

interface Props {
  siteTitle: string
}

const Navbar = ({ siteTitle }: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  let menu: JSX.Element | undefined = undefined
  if (isOpen) {
    menu = <Menu open={true} />
  }

  const handleMenuTap = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <Style.FlexBoxNav>
        <LeftBox>
          <Burger isOpen={isOpen} onClick={handleMenuTap}></Burger>
        </LeftBox>
        <Style.Box>
          <NavLink.Title to="/" fontWeight={700} fontSize={[15, 18, 22, 23]}>
            {siteTitle}
          </NavLink.Title>
        </Style.Box>
        <Style.Box>
          <Style.List>
            <Style.RightListItem>
              <LinkedInIcon />
            </Style.RightListItem>
            <Style.RightListItem>
              <GithubIcon />
            </Style.RightListItem>
          </Style.List>
        </Style.Box>
      </Style.FlexBoxNav>
      {menu}
    </>
  )
}

export default Navbar
