import styled, { css } from "styled-components"

const noPaddingMargin = css`
  margin: 0px;
  padding: 0px;
`

const textStyle = css`
  /* color: #2d3436; */
  text-decoration: none;
`

const List = styled.ul`
  ${noPaddingMargin}
`

const LeftListItem = styled.li`
  ${textStyle};
  padding-right: 15px;
  list-style: none;
  display: inline-block;
`

const RightListItem = styled.li`
  ${textStyle};
  padding-left: 15px;
  list-style: none;
  display: inline-block;
`

const Box = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  text-align: center;
  &:first-child ${List} {
    margin-right: auto;
  }
  &:last-child ${List} {
    margin-left: auto;
  }
`

const FlexBoxNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
`

export default {
  Box,
  FlexBoxNav,
  List,
  LeftListItem,
  RightListItem,
}
