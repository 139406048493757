import { useState, useEffect } from "react"

const getDefaultWidth = () => {
  if (typeof window !== `undefined`) {
    return getWidth()
  }
  return 900
}

const getWidth = () => {
  return (
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth
  )
}

function useWindowWidth() {
  // save current window width in the state object
  let [width, setWidth] = useState(getDefaultWidth())

  // in this case useEffect will execute only once because
  // it does not have any dependencies.
  useEffect(() => {
    // set the width on initial load
    setWidth(getWidth())
    // timeoutId for debounce mechanism
    let timeoutId: number | undefined = undefined
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId)
      // change width from the state object after 150 milliseconds
      timeoutId = setTimeout(() => setWidth(getWidth()), 150)
    }
    // set resize listener
    window.addEventListener("resize", resizeListener)

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", resizeListener)
    }
  }, [])

  return width
}

export default useWindowWidth
