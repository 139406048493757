import React, { useEffect } from "react"
import { bool } from "prop-types"
import Style from "./menu.style"
import NavStyle from "./navbar.style"
import NavLink from "./navlink"
import { typographyStyles } from "../../styleguide/typography-styles"

interface IMenuProps {
  open: boolean
}

const Menu = ({ open, ...props }: IMenuProps) => {
  const isHidden = open ? true : false
  const tabIndex = isHidden ? 0 : -1

  useEffect(() => {
    document.body.style.overflow = "hidden"
    return () => {
      document.body.style.overflow = "unset"
    }
  }, [])

  return (
    <Style.Menu aria-hidden={!isHidden} {...props}>
      <NavStyle.List>
        <Style.PageLink to="/">WORK</Style.PageLink>
        <Style.PageLink to="about">ABOUT</Style.PageLink>
        <Style.PageLink to="contact">CONTACT</Style.PageLink>
        <Style.ExternalLink
          href="https://proteinandprogramming.com"
          target="_blank"
        >
          BLOG
        </Style.ExternalLink>
      </NavStyle.List>
    </Style.Menu>
  )
}

Menu.propTypes = {
  open: bool.isRequired,
}

export default Menu
