import React from "react"

import Style from "./navbar.style"
import LinkedInIcon from "../icon-linked-in"
import GithubIcon from "../icon-github"
import NavLink from "./navlink"

interface Props {
  siteTitle: string
}

const Navbar = ({ siteTitle }: Props) => {
  return (
    <Style.FlexBoxNav>
      <Style.Box>
        <Style.List>
          <Style.LeftListItem>
            <NavLink.Page to="/">WORK</NavLink.Page>
          </Style.LeftListItem>
          <Style.LeftListItem>
            <NavLink.Page to="/about">ABOUT</NavLink.Page>
          </Style.LeftListItem>
          <Style.LeftListItem>
            <NavLink.Page to="/contact">CONTACT</NavLink.Page>
          </Style.LeftListItem>
          <Style.LeftListItem>
            <NavLink.External
              href="https://proteinandprogramming.com"
              target="_blank"
            >
              BLOG
            </NavLink.External>
          </Style.LeftListItem>
        </Style.List>
      </Style.Box>
      <Style.Box>
        <NavLink.Title to="/" fontWeight={700} fontSize={[20, 21, 22, 23]}>
          {siteTitle}
        </NavLink.Title>
      </Style.Box>
      <Style.Box>
        <Style.List>
          <Style.RightListItem>
            <LinkedInIcon />
          </Style.RightListItem>
          <Style.RightListItem>
            <GithubIcon />
          </Style.RightListItem>
        </Style.List>
      </Style.Box>
    </Style.FlexBoxNav>
  )
}

export default Navbar
