import React from "react"

interface IWithButton {
  url: string
  children: JSX.Element
}

const WithButton = (props: IWithButton) => {
  return (
    <a href={props.url} target="_blank">
      {props.children}
    </a>
  )
}

export default WithButton
