import React from "react"
import styled, { css } from "styled-components"
import { LinkGetProps } from "@reach/router"
import { Link as GatsbyLink, GatsbyLinkProps } from "gatsby"

import DynamicStyledSystemComponent, {
  StyledSystemProps,
} from "../../styleguide/dynamic-styled-component"
import { typographyStyles } from "../../styleguide/typography-styles"
import { colors } from "../../styleguide/colors"

type LinkProps = StyledSystemProps &
  Pick<GatsbyLinkProps<unknown>, "to"> & { strictMatch?: boolean }

const normalStyle = css`
  text-decoration: none;
  color: ${colors.primary};
  &:focus {
    color: ${colors.primary};
    border-bottom: 2px solid ${colors.primary};
  }
`

const noUnderlineStyle = css`
  ${normalStyle}
  &:focus {
    border-bottom: 0px;
  }
`

const GatsbyNavLink = styled(GatsbyLink)`
  ${normalStyle}
`

const isPartiallyActive = ({ isCurrent }: LinkGetProps) => {
  return isCurrent
    ? {
        style: {
          // border-bottom has to be camel case for gatsby Link to use
          borderBottom: `2px solid ${colors.primary}`,
          color: colors.primary,
        },
      }
    : {}
}

// const isCurrentlyActive = (
//   { isPartiallyCurrent, isCurrent }: LinkGetProps,
//   strictMatch?: boolean
// ) => {
//   const activeStyle = {
//     style: {
//       "border-bottom": `2px solid ${colors.primary}`,
//       color: colors.primary,
//     },
//   }
//   if (strictMatch === true) {
//     console.log("is strict match")
//     return isCurrent ? activeStyle : {}
//   } else {
//     console.log("is not strict match")
//     return isPartiallyCurrent ? activeStyle : {}
//   }
// }

const Page: React.FC<LinkProps> = ({ to, children, ...props }) => {
  return (
    <DynamicStyledSystemComponent {...typographyStyles.NavLink} {...props}>
      <GatsbyNavLink to={to} getProps={isPartiallyActive} {...props}>
        {children}
      </GatsbyNavLink>
    </DynamicStyledSystemComponent>
  )
}

const NavTitle = styled(GatsbyNavLink)`
  ${noUnderlineStyle}
`

const Title: React.FC<LinkProps> = ({ to, children, ...props }) => {
  return (
    <DynamicStyledSystemComponent {...typographyStyles.NavLink} {...props}>
      <NavTitle to={to} {...props}>
        {children}
      </NavTitle>
    </DynamicStyledSystemComponent>
  )
}

type ExternalLinkProps = StyledSystemProps & { href: string; target?: string }

const ATag = styled.a`
  ${noUnderlineStyle}
`

const External: React.FC<ExternalLinkProps> = ({
  href,
  target,
  children,
  ...props
}) => {
  return (
    <DynamicStyledSystemComponent {...typographyStyles.NavLink} {...props}>
      <ATag href={href} target={target} {...props}>
        {children}
      </ATag>
    </DynamicStyledSystemComponent>
  )
}

export default {
  Title,
  Page,
  External,
}
