import * as CSS from "csstype"

export interface ThemeColors {
  primary: CSS.ColorProperty
  link: CSS.ColorProperty
  success: CSS.ColorProperty
  warning: CSS.ColorProperty
  error: CSS.ColorProperty
  heading: CSS.ColorProperty
  text: CSS.ColorProperty
  disabled: CSS.ColorProperty
  border: CSS.ColorProperty
  subdued: CSS.ColorProperty
  secondary: CSS.ColorProperty
  action: CSS.ColorProperty
}

export const colors: ThemeColors = {
  primary: "#2D3436",
  link: "#1890ff",
  success: "#52c41a",
  warning: "#faad14",
  error: "#e84118",
  heading: "#2D3436",
  text: "#000",
  disabled: "#f5222d",
  border: "#2D3436",
  subdued: "#dfe6e9",
  secondary: "#b2bec3",
  action: "#00b894",
}
