import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"

import Navbar from "./nav/navbar"
import MobileNavbar from "./nav/navbar-mobile"
import useWindowWidth from "../hooks/use-window-width"

interface Props {
  siteTitle: string
}

const Header = ({ siteTitle }: Props) => {
  let windowWidth = useWindowWidth()
  const [mobileIsOpen, setMobileIsOpen] = useState(false)

  let navbar: JSX.Element
  if (windowWidth > 820) {
    navbar = <Navbar siteTitle={siteTitle} />
  } else {
    navbar = (
      <div>
        <MobileNavbar siteTitle={siteTitle} />
      </div>
    )
  }

  return (
    <header
      style={{
        marginBottom: `1.45rem`,
      }}
    >
      {/* <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>
    </div> */}
      {navbar}
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
