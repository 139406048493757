import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import WithButton from "./with-button"

const Icon = () => {
  const data = useStaticQuery(graphql`
    query {
      icon: file(relativePath: { eq: "icon-github-black.png" }) {
        childImageSharp {
          fixed(width: 25, height: 25) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <WithButton url="https://github.com/aj-bartocci">
      <Img fixed={data.icon.childImageSharp.fixed} />
    </WithButton>
  )
}

export default Icon
