import React, { useState, useEffect } from "react"
import { Transition } from "react-transition-group"

import { Animation } from "./FadeInLayout.style"

interface Props {}

type FadeInProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>

//  const duration = 300;

//   const defaultStyle = {
//     transition: `opacity ${duration}ms ease-in-out`,
//     opacity: 0,
//   }

//   interface ITransitionStyles {
//     TransitionStatus: Object;
//   }

//   const transitionStyles = {
//     entering: { opacity: 1 },
//     entered:  { opacity: 1 },
//     exiting: { },
//     exited: { },
//     unmounted: { },
//   };

//   const Fade = () => (
//     <Transition in={true} timeout={duration}>
//       {(state) => (
//         <div style={{
//           ...defaultStyle,
//           ...transitionStyles[state]
//         }}>
//           I'm A fade Transition!
//         </div>
//       )}
//     </Transition>
//   );

const FadeInLayout = ({ children, ...props }: FadeInProps) => {
  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    setAnimate(true)
    return () => {
      setAnimate(false)
    }
  }, [])

  return (
    <div {...props}>
      <Transition
        in={animate}
        timeout={{
          enter: 1000,
          exit: 1000,
        }}
      >
        {state => (
          // state change: exited -> entering -> entered -> exiting -> exited
          // <Animation state={state}>Hello</Animation>
          // console.log(`anim state = ${state}`)
          <Animation status={state}>{children}</Animation>
        )}
      </Transition>
    </div>
  )
}

export default FadeInLayout
