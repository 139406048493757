import styled from "styled-components"

const Layout = styled.div`
  text-align: center;
`

// export const ConstrainedLayout = styled(Layout)`
//   max-width: 800px;
//   display: inline-block;
//   text-align: left;
//   margin-top: 15px;
// `

export default {
  Layout,
}
