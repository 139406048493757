import styled from "styled-components"

interface IStyledBurger {
  isOpen: boolean
}

export const StyledBurger = styled.button<IStyledBurger>`
  /* position: absolute; */
  /* top: 5%; */
  /* left: 2rem; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 1.75rem;
  height: 1.75rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 300;
  text-align: left;

  &:focus {
    outline: none;
  }

  div {
    width: 1.75rem;
    height: 0.25rem;
    background: black;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${p => (p.isOpen ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${p => (p.isOpen ? "0" : "1")};
    }

    :nth-child(3) {
      transform: ${p => (p.isOpen ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`
